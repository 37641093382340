.tariffs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;

  ul {
    margin-top: 20px;
  }

  li {
    margin-bottom: 15px;
  }

  span {
    margin-left: 5px;
    color: #76B729;
  }
}

.tariffs-head {
  font-size: 18px;
  font-weight: 700;
}