.qr-code-wrap {
  margin: 20px 0;
  padding: 20px;
  border-radius: 16px;
  background-color: #fff;
}

.instructions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
}

.instructions-head {
  font-size: 24px;
  line-height: 41px;
  font-weight: 700;
}