.input-wrap-phone {
  margin: 30px 0;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
  margin-bottom: 20px;

  input {
    width: 15%;
    margin: 5px 10px;
    padding: 15px 10px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    background: transparent;
    color: #FFFFFF;
    font-size: 28px;
    outline: none;
    text-align: center;

    &:focus-visible {
      border: 1px solid #76B729;
    }
  }
}

.confirm-wrap {
  margin-bottom: 25px;
}

.confirm-text {
  font-size: 14px;
  font-style: italic;

  span {
    font-size: 16px;
  }
}