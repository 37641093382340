.app {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(210.55deg, #2B114B 6.38%, #25054E 102.04%);
  color: #FFF;
}

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 24px 20px 0;
  background-repeat: no-repeat;
  background-position: 0 90%;
  overflow-y: scroll;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding-bottom: 40px;

  img {
    width: 100%;
  }
}

.button {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #76B729;
  border: none;
  border-radius: 8px;
  color: #2E1A47;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  text-decoration: none;
}