.button-back {
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-decoration: none;

  span {
    margin-left: 5px;
    color: #FFFFFF;
    font-size: 14px;
    line-height: 14px;

    opacity: .96;
  }

  & + .title {
    margin-top: 20px;
  }
}

.button-tab {
  height: 36px;
  padding: 10px 24px;
  background: transparent;
  border: 1px solid #76B729;
  border-radius: 50px;
  color: #FFFFFF;
  font-size: 14px;
  line-height: 14px;
  
  &.active {
    background-color: #76B729;
    color: #2E1A47;
  }

  &:first-child {
    margin-right: 10px;
  }
}

.input-wrap {
  width: 100%;

  label {
    display: flex;
    font-size: 12px;
    line-height: 12px;
    font-weight: 300;
  }
}

.input-container {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;

  input {
    width: 100%;
    margin-top: 5px;
    padding: 15px 40px 15px 20px;
    border-radius: 10px;
    border: 1px solid #FFFFFF;
    background: transparent;
    color: #FFFFFF;
    font-size: 17px;
    outline: none;

    &:focus-visible {
      border: 1px solid #76B729;
    }
  }

  .currency {
    position: absolute;
    right: 10px;
    opacity: .5;
  }
}

.input {
  display: flex;
  align-items: center;
}

.sep {
  margin: 0 22px;
  height: 36px;
  border: 1px solid #76B729;
  box-shadow: 0 0 6.40587px rgba(44, 213, 196, 0.7),
  0 0 24.7992px rgba(44, 213, 196, 0.5),
  inset 0 0 2.47992px rgba(44, 213, 196, 0.5);
}

.amounts-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 15px;
}

.amount-box {

  .field {
    font-size: 14px;
  }

  .field-accent {
    margin-left: 5px;
    color: #76B729;
    font-size: 20px;

    span {
      margin-left: 5px;
      font-size: 14px;
    }
  }

  .field-small {
    font-size: 10px;
    opacity: .8;
  }
}

.pay-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;

  .google-pay-btn {
    width: 100%;
  }
}