.banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  background-color: #fff;
  z-index: 1;
}

.banner-close {
  width: 20px;
  margin-right: 10px;
}

.banner-content {
  display: flex;
  align-items: center;
}

.banner-text {
  margin-left: 10px;
  color: #2b124c;
  text-align: left;
}

.banner-image {
  width: 50px;
  border-radius: 8px;
}

.banner-button {
  .button {
    width: 100px;
  }
}